<template>
    <div>
        <nav-bar title="崆峒山电子卡" url="/"></nav-bar>
        <div class="content">
            <img :src="info.user_nianka_photo" @click="preview(info.user_nianka_photo)">
        </div>
    </div>
</template>

<script>
    import { card_order_detail } from '@/api/card.js';
    import {
        ImagePreview,
        Dialog
    } from 'vant';
    export default {
        name: 'photo',
        data() {
            return {
                info: {},
            }
        },
        metaInfo() {
            return {
                title: '崆峒山电子卡' + '-' + this.config.site.name,
            }
        },
        created() {
            this.get_data();
        },
        methods: {
            get_data() {
                var that = this;
                card_order_detail({
                    id: this.$route.params.id,
                }).then(res => {
                    if (res.code == 1) {
                        if (res.data.state !== 8) {
                            Dialog.alert({
                                title: '提示',
                                message: '年卡未办理成功！',
                            }).then(function () {
                                that.url('/');
                            });
                            return false;
                        }
                        this.info = res.data;
                    }
                });
            },

            //图片预览
            preview(img) {
                ImagePreview({
                    images: [img],
                    startPosition: 0
                });
            },

            url(path) {
                if (path.indexOf("http") != -1) {
                    window.location.href = path;
                } else {
                    if (path == this.$store.getters.unionid) {
                        this.$router.back();
                    } else {
                        this.$router.push(path);
                    }
                }
            },
        }
    }
</script>

<style scoped>
    .content {
        text-align: center;
        padding: 15px;
    }

    .content img {
        width: 100%;
    }
</style>